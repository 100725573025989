const movieInfo = [
  {
    id: 0,
    title: '',
    imageLink: '',
    posterLink: '',
    actors: '',
    rated: '',
    imdbRating: 0.0,
    boxOffice: '',
    genre: '',
    runtime: 0,
    releaseDate: '',
    director: '',
  },
];

export default movieInfo;
